import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';

export default function Footer(props) {
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    return (
        <footer className="footer">
            <div className="inner">
                <div className="details">
                    <div className="contact">
                        <span className="copyright">{"© "+currYear+" Ignition"}</span>
                        <span className="address" dangerouslySetInnerHTML={{__html:config.address}}></span>
                        <span className="phone" dangerouslySetInnerHTML={{__html:config.phone}}></span>
                        <span className="email" dangerouslySetInnerHTML={{__html:config.email}}></span>
                    </div>

                    <nav className="links">
                        {config.links.map((item, key) => {
                            return(
                                <a className="link" key={key} href={item.url} target={item.blank===true?"_blank":"_self"}>
                                    <span className="arrow"></span>
                                    <span className="name">{item.name}</span>
                                </a>
                            );
                        })}
                    </nav>
                </div>
                
                <img className="logo" src={media[config.logo]} />
            </div>
        </footer>
    )
}
