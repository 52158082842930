import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';


export default function PostLink(props) {
    return (
        <a className="post-link" href={"/blog/"+props.content.slug}>
            {props.content['_embedded'].hasOwnProperty('wp:featuredmedia') ?
                <img src={props.content['_embedded']['wp:featuredmedia'][0]['source_url']} />
            :
                <img src={media[config.defaultThumb]} />
            }
            <h3 className="title" dangerouslySetInnerHTML={{__html:props.content.title.rendered}}></h3>
            <span className="date">{props.content.date}</span>
        </a>
    )
}
