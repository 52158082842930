import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import media from '../../media';

export default function VideoStatic(props) {
    const [videoControls, setVideoControls] = useState(false);
    const [videoLoop, setVideoLoop] = useState(true);
    const [videoMuted, setVideoMuted] = useState(true);

    const refVideo = useRef(null);

    return (
        <div className="video-static">
            <video ref={refVideo} controls={videoControls} controlsList="nodownload" autoPlay loop={videoLoop} muted={videoMuted}>
                <source src={props.src} type="video/mp4" />
            </video>
        </div>
    );
}
