import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import VideoScreen from "../../components/video-screen/main";
import VideoStatic from "../../components/video-static/main";

export default function StudyBody(props) {
    return (
        <div className="study-body">
            <span className="brand">{props.content.brand}</span>

            <h1 className={"title"+(props.content.summary?"":" no-summary")}>{props.title}</h1>

            {props.content.summary && <p className="summary">{props.content.summary}</p>}

            <div className="text-section section1">
                <div className="text-body benefits">
                    <h2 className="heading">{props.content.benefits.heading}</h2>

                    <div className="paragraphs">
                        {props.content.benefits.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </div>

                <div className="items-body time">
                    <h2 className="heading">{props.content.time.heading}</h2>

                    <ul className="items">
                        {props.content.time.body.map((item, key) => {
                            return(
                                <li key={key} className="item">
                                    <span className="arrow"></span>
                                    <span className="text">{item}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="items-body services">
                    <h2 className="heading">{props.content.services.heading}</h2>

                    <ul className="items">
                        {props.content.services.body.map((item, key) => {
                            return(
                                <li key={key} className="item">
                                    <span className="arrow"></span>
                                    <span className="text">{item}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="text-body outcomes">
                    <h2 className="heading">{props.content.outcomes.heading}</h2>

                    <div className="paragraphs">
                        {props.content.outcomes.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="section2">
                {props.content.img1 && <img className="img1" src={media[props.content.img1]} />}
                {props.content.video1 && <VideoScreen
                    content={props.content.video1}
                    playOnFs={false}
                    onVideoPlay={()=>{if (props.onVideoPlay) {props.onVideoPlay();}}}
                    onVideoStop={()=>{if (props.onVideoStop) {props.onVideoStop();}}}
                />}
                {props.content.static1 && <VideoStatic src={props.content.static1} />}
            </div>

            <div className="text-section section3">
                <div className="text-body background">
                    <h2 className="heading">{props.content.background.heading}</h2>

                    <div className="paragraphs">
                        {props.content.background.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </div>

                <div className="text-body goals">
                    <h2 className="heading">{props.content.goals.heading}</h2>

                    <div className="paragraphs">
                        {props.content.goals.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="section4">
                <div className="col col1">
                    <img src={media[props.content.img2]} />
                </div>
                <div className="col col2">
                    <img src={media[props.content.img3]} />
                </div>
            </div>

            <div className="text-section section5">
                <div className="text-body results">
                    <h2 className="heading">{props.content.results.heading}</h2>

                    <div className="paragraphs">
                        {props.content.results.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </div>

                {props.content.engagement &&
                    <div className="text-body engagement">
                        <h2 className="heading">{props.content.engagement.heading}</h2>

                        <div className="paragraphs">
                            {props.content.engagement.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
