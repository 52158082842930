import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../../media';
import Layout from "../../../components/layout/main";
import StudyWrapper from "../../../components/study-wrapper/main";
import SectionHeading from "../../../components/section-heading/main";
import ProjectsSlider from "../../../components/projects-slider/main";

export default function Hybrid(props) {
    const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);

    const scrollPos = useRef(0);

    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    //On isVideoFullscreen change
    useEffect(() => {
        if (isVideoFullscreen===false) {
            window.scrollTo(0, scrollPos.current);
        }
    }, [isVideoFullscreen]);

    return (
        <div className="hybrid">
            <Layout pageId={0} locked={isVideoFullscreen}>
                <StudyWrapper
                    bg={config.bg}
                    banner={config.banner}
                    body={config.body}
                    title={config.title}
                    onVideoPlay={()=>{scrollPos.current=window.scrollY; setIsVideoFullscreen(true)}}
                    onVideoStop={()=>{setIsVideoFullscreen(false)}}
                />

                <div className="projects-block">
                    <div className="inner-content">
                        <SectionHeading text={config.projects.heading} />

                        <ProjectsSlider current={config.projects.current} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}
