import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import {sendEmail, sendContactEmail} from '../../utilities/general';
import Layout from "../../components/layout/main";
import Loader from "../../components/loader/main";
import MainHeading from "../../components/main-heading/main";

export default function Contact(props) {
    const [formDisabled, setFormDisabled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [showResponse, setShowResponse] = useState(false);

    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comments, setComments] = useState("");
    const [termsChecked, setTermsChecked] = useState(false);

    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="contact">
            <Layout pageId={4}>
                <div className="form-block">
                    <div className="inner-content">
                        <MainHeading content={config.form.heading} />

                        <form className="form-screen" onSubmit={onFormSubmit}>
                            <div className="form-row">
                                <div className="form-col form-col1">
                                    <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="first" disabled={formDisabled?"disabled":""} placeholder={config.form.first} value={first} onChange={(e)=>{setFirst(e.target.value)}} />
                                    <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="last" disabled={formDisabled?"disabled":""} placeholder={config.form.last} value={last} onChange={(e)=>{setLast(e.target.value)}} />
                                    <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="company" disabled={formDisabled?"disabled":""} placeholder={config.form.company} value={company} onChange={(e)=>{setCompany(e.target.value)}} />
                                    <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="phone" disabled={formDisabled?"disabled":""} placeholder={config.form.phone} value={phone} onChange={(e)=>{setPhone(e.target.value.replace(/\D/g,''))}} />
                                    <input className={"text-input"+(formDisabled?" disabled":"")} type="email" name="email" disabled={formDisabled?"disabled":""} placeholder={config.form.email} value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                </div>

                                <div className="form-col form-col2">
                                    <textarea className={"text-area"+(formDisabled?" disabled":"")} name="comments" disabled={formDisabled?"disabled":""} placeholder={config.form.comments} value={comments} onChange={(e)=>{setComments(e.target.value)}}></textarea>
                                </div>
                            </div>

                            <div className="form-row terms-row">
                                <div className="checkbox-wrapper">
                                    <input className={"checkbox-input"+(formDisabled?" disabled":"")} type="checkbox" name="terms" disabled={formDisabled?"disabled":""} checked={termsChecked} onChange={(e)=>{setTermsChecked(termsChecked?false:true)}} />
                                    <label className={"checkbox-label"} htmlFor="terms" dangerouslySetInnerHTML={{__html:config.form.terms}}></label>
                                </div>
                            </div>

                            {showResponse ?
                                <div className="response">
                                    {config.form.response.map((paragraph, key) => {
                                        return(
                                            <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                        );
                                    })}
                                </div>
                            :
                                <div className="buttons-holder">
                                    {isSending ?
                                        <Loader />
                                    :
                                        <input className="submit-btn" type="submit" value={config.form.submitBtn} onClick={onFormSubmit} />
                                    }
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )

    function isEmailCorrect(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function onFormSubmit(e) {
        e.preventDefault();

        //Validate form
        if (first==="" || last==="" || email==="" || comments==="" || termsChecked===false) {
            alert(config.form.error1);
            return;
        }

        if (isEmailCorrect(email)===false) {
            alert(config.form.error2);
            return;
        }

        //Send email
        setIsSending(true);

        var body = {
            inf_field_Email: email,
            inf_field_FirstName: first,
            inf_field_LastName: last,
            inf_field_Company: company,
            inf_field_Phone1: phone,
            inf_custom_Message: comments,
            terms: "on"
        }

        var res = await sendContactEmail(body);

        setIsSending(false);
        setFormDisabled(true);
        setShowResponse(true);
    }
}
