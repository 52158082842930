import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import ProjectLink from "../project-link/main";
import AnimationOnScroll from "../animation-on-scroll/main";

export default function ProjectsGallery(props) {
    return (
        <div className="projects-gallery">
            <div className="projects">
                {config.projects.map((project, key) => {
                    return(
                        <div className={"project project"+(key+1)} key={key}>
                            <AnimationOnScroll animation="scale">
                                <ProjectLink content={project} format={"gallery"} />
                            </AnimationOnScroll>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
