import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import StudyBanner from "../../components/study-banner/main";
import StudyBody from "../../components/study-body/main";

export default function StudyWrapper(props) {
    return (
        <div className="study-wrapper" style={{backgroundImage:"url("+media[props.bg]+")"}}>
            <StudyBanner content={props.banner} title={props.title} />

            <div className="inner-content">
                <StudyBody
                    content={props.body}
                    title={props.title}
                    onVideoPlay={()=>{if (props.onVideoPlay) {props.onVideoPlay();}}}
                    onVideoStop={()=>{if (props.onVideoStop) {props.onVideoStop();}}}
                />
            </div>
        </div>
    );
}
