import React, {useEffect, useState} from 'react';
import './style.scss';
import config from '../projects-gallery/config.json';
import media from '../../media';
import Slider from "../slider/main";
import ProjectLink from "../project-link/main";

export default function ProjectsSlider(props) {
    const [projects, setProjects] = useState(null);
    const [slideWidth, setSlideWidth] = useState(507);
    const [slideGap, setSlideGap] = useState(10);

    //On component mount
    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        ignoreCurrentProject();
        onWindowResize();

        return () => {
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);

    return (
        <div className="projects-slider">
            {projects!==null &&
                <Slider slideWidth={slideWidth} slideGap={slideGap}>
                    {projects.map((project, key) => {
                        return(
                            <div className="project" key={key}>
                                <ProjectLink content={project} format={"slider"} />
                            </div>
                        );
                    })}
                </Slider>
            }
        </div>
    );

    function ignoreCurrentProject() {
        var temp = [];
        var i;

        for (i=0; i<config.projects.length; i++) {
            if (props.current!==i) {
                temp.push(config.projects[i]);
            }
        }

        setProjects(temp);
    }

    function onWindowResize() {
        if (window.innerWidth<565) {
            setSlideWidth(250);
            setSlideGap(10);
        }
        else {
            setSlideWidth(507);
            setSlideGap(10);
        }
    }
}
