import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import SplashScreen from "../../components/splash-screen/main";
import SectionHeading from "../../components/section-heading/main";
import Carousel from "../../components/carousel/main";

export default function Services(props) {
    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="services">
            <Layout pageId={1}>
                <div className="splash-block">
                    <div className="inner-content">
                        <div className="bg-img1"></div>
                        <div className="bg-img2"></div>

                        <SplashScreen content={config.splash} />
                    </div>
                </div>

                <div className="services-block">
                    <Carousel scrollDirection={"left"} scrollDuration={36}>
                        {config.services.map((service, key) => {
                            return(
                                <span className="service" key={key} dangerouslySetInnerHTML={{__html:service}}></span>
                            );
                        })}
                    </Carousel>
                </div>

                <div className="skills-block">
                    <div className="inner-content">
                        <div className="svg-holder">
                            <img src={media[config.skills.svg]}/>
                        </div>

                        <div className="txt-holder">
                            <div className="title">
                                <h3 dangerouslySetInnerHTML={{__html:config.skills.title}}></h3>
                            </div>

                            <div className="paragraphs">
                                {config.skills.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <Carousel scrollDirection={"right"} scrollDuration={50}>
                        {config.skills.tiles.map((tiles, key) => {
                            return(
                                <div className="tiles" key={key}>
                                    <div className="col col1">
                                        <div className="row row1">
                                            <div className="subcol subcol1">
                                                <img src={media[tiles.small1]} />
                                            </div>
                                            <div className="subcol subcol2">
                                                <img src={media[tiles.small2]} />
                                            </div>
                                        </div>
                                        <div className="row row2">
                                            <img src={media[tiles.medium]} />
                                        </div>
                                    </div>
                                    <div className="col col2">
                                        <img src={media[tiles.large]} />
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>

                <div className="philosophy-block">
                    <div className="inner-content">
                        <div className="svg-holder">
                            <img src={media[config.philosophy.svg]}/>
                        </div>

                        <div className="txt-holder">
                            <div className="title">
                                <h3 dangerouslySetInnerHTML={{__html:config.philosophy.title}}></h3>
                            </div>

                            <div className="paragraphs">
                                {config.philosophy.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
