import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import AnimationOnScroll from "../../components/animation-on-scroll/main";
import SplashScreen from "../../components/splash-screen/main";
import SectionHeading from "../../components/section-heading/main";
import VideoScreen from "../../components/video-screen/main";
import Accordion from "../../components/accordion/main";
import Carousel from "../../components/carousel/main";

export default function About(props) {
    const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);

    const scrollPos = useRef(0);

    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    //On isVideoFullscreen change
    useEffect(() => {
        if (isVideoFullscreen===false) {
            window.scrollTo(0, scrollPos.current);
        }
    }, [isVideoFullscreen]);

    return (
        <div className="about">
            <Layout pageId={2} locked={isVideoFullscreen}>
                <div className="splash-block">
                    <div className="inner-content">
                        <div className="bg-img1"></div>
                        <div className="bg-img2"></div>

                        <SplashScreen content={config.splash} />
                    </div>
                </div>

                <div className="video-block">
                    <VideoScreen
                        content={config.culture.video}
                        onVideoPlay={()=>{scrollPos.current=window.scrollY; setIsVideoFullscreen(true)}}
                        onVideoStop={()=>{setIsVideoFullscreen(false)}}
                    />
                </div>

                <div className="culture-block">
                    <div className="inner-content">
                        <Accordion bars={config.culture.bars} type="multiple">
                            <div className="box">
                                <div className="paragraphs">
                                    {config.culture.mission.map((para, key) => {
                                        return(
                                            <p key={key} dangerouslySetInnerHTML={{__html:para}}></p>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="box">
                                <div className="paragraphs">
                                    {config.culture.vision.map((para, key) => {
                                        return(
                                            <p key={key} dangerouslySetInnerHTML={{__html:para}}></p>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="box">
                                <ul className="list">
                                    {config.culture.values.list.map((item, key) => {
                                        return(
                                            <li className="item" key={key}>
                                                <span className="arrow"></span>
                                                <span className="text" dangerouslySetInnerHTML={{__html:item}}></span>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <div className="extra">
                                    {config.culture.values.body.map((para, key) => {
                                        return(
                                            <p key={key} dangerouslySetInnerHTML={{__html:para}}></p>
                                        );
                                    })}
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>

                <div className="awards-block">
                    <div className="inner-content">
                        <SectionHeading text={config.awards.heading} />
                    </div>

                    <div className="awards">
                        <Carousel scrollDirection={"left"} scrollDuration={40}>
                            {config.awards.list.map((award, key) => {
                                return(
                                    <div className="award" key={key}>
                                        <img src={media[award]} />
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>

                <div className="team-block">
                    <div className="inner-content">
                        <SectionHeading text={config.team.heading} />

                        <div className="members">
                            {config.team.members.map((member, key) => {
                                return (
                                    <div className="member" key={key}>
                                        <AnimationOnScroll>
                                            <img src={media[member.img]}/>
                                            <span className="name" dangerouslySetInnerHTML={{__html:member.name}}></span>
                                            <span className="title" dangerouslySetInnerHTML={{__html:member.title}}></span>
                                        </AnimationOnScroll>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
