import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import {getWpDocs, simplifyWpDate} from '../../utilities/general';
import Loader from "../../components/loader/main";
import PostLink from "../../components/post-link/main";


export default function LatestPosts(props) {
    const [posts, setPosts] = useState(null);

    //On component mount
    useEffect(() => {
        loadWpData();
    }, []);

    return (
        <div className="latest-posts">
            {posts===null ?
                <div className="loader-holder">
                    <Loader />
                </div>
            :
                posts.length>0 ?
                    <div className="posts">
                        {posts.map((post, key) => {
                            return(
                                <div className="post" key={key}>
                                    <PostLink content={post} />
                                </div>
                            );
                        })}
                    </div>
                :
                    <p className="not-found">{config.notFound}</p>
            }
        </div>
    )

    async function loadWpData() {
        var latestPosts = await getWpDocs("posts", {_embed:null, per_page:4, page:1});

        optimisePostsData(latestPosts);

        //Update state
        setPosts(latestPosts);
    }

    function optimisePostsData(postsList) {
        var i;

        for (i=0; i<postsList.length; i++) {
            postsList[i].date = simplifyWpDate(postsList[i].date); //Simplify date
        }
    }
}
