import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function ProjectsLink(props) {
    return (
        <a className={"project-link"+(props.format==="gallery"?" gallery-format":"")} href={props.content.url}>
            <span className="img-holder">
                <img src={props.format==="gallery"?media[props.content.thumb_gallery]:media[props.content.thumb_slider]}/>
                <span className="overlay"></span>
            </span>

            <span className="brand">{props.content.brand}</span>
            <span className="title">{props.content.title}</span>
        </a>
    );
}
