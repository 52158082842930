import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import AnimationOnScroll from "../animation-on-scroll/main";


export default function SplashScreen(props) {
    return (
        <div className="splash-screen">
            <AnimationOnScroll>
                <div className="heading-holder">
                    {props.content.svg && <img className="svg" src={media[props.content.svg]}/>}

                    <h2 dangerouslySetInnerHTML={{__html:props.content.heading}}></h2>
                </div>

                <div className="img1-holder">
                    <img src={media[props.content.img1]} />
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll>
                <div className="img2-holder">
                    {props.content.img2 && <img src={media[props.content.img2]} /> }
                </div>

                <div className="body-holder">
                    <div className="body-wrapper">
                        <span className="line"></span>
                        <div className="paragraphs">
                            {props.content.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>

            <span className="arrow"></span>
        </div>
    )
}
