import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';

export default function Topbar(props) {
    const [showTopbar, setShowTopbar] = useState(true);
    const [showPanel, setShowPanel] = useState(false);

    const scrollPos = useRef(0);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        window.addEventListener('scroll', onWindowScroll);

        return () => {
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener("scroll", onWindowScroll);
        }
    }, []);

    return (
        <div className={"topbar"+((showTopbar===false && showPanel===false)?" hidden":"")}>
            <div className="inner">
                <a className="logo" href="/"></a>

                <nav className="tabs">
                    {config.tabs.map((tab, key) => {
                        return(
                            <a className={"tab"+(props.pageId===key?" active":"")} key={key} href={tab.url}>{tab.name}</a>
                        );
                    })}
                </nav>

                <a className={"menu-btn"+(showPanel?" active":"")} onClick={onMenuClick}>
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                </a>
            </div>

            <div className={"overlay"+(showPanel?" open":"")}>
                <div className="panel">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <a className="tab" key={key} href={tab.url}>
                                    <span className="arrow"></span>
                                    <span className="text">{tab.name}</span>
                                </a>
                            );
                        })}
                    </nav>
                </div>
            </div>
        </div>
    )

    function onMenuClick(e) {
        e.preventDefault();

        setShowTopbar(true);

        if (showPanel) {
            setShowPanel(false);
        }
        else {
            setShowPanel(true);
        }
    }

    function onWindowResize() {
        setShowPanel(false);
        setShowTopbar(true);
    }

    function onWindowScroll() {
        if (scrollPos.current<window.scrollY) {
            //Scrolling down
            if (window.scrollY>200) {
                setShowTopbar(false);
            }
        }
        else {
            //Scrolling up
            setShowTopbar(true);
        }

        scrollPos.current = window.scrollY;
    }
}
